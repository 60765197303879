@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "antd/dist/antd.css";

* {
	font-family: "Poppins", sans-serif;
}

@layer components {
	.btn {
		@apply text-sm text-white font-normal rounded-md py-2 px-3 focus:outline-none;
	}

	.btn-primary {
		@apply bg-indigo-500 active:bg-indigo-700 text-white;
	}

	.btn--secondary {
		@apply bg-gray-100 text-black;
	}
}

.btn {
	@apply text-sm text-white font-normal rounded-md py-2 px-3 transition-colors bg-indigo-500 hover:bg-indigo-400 active:bg-indigo-600 focus:outline-none;
}

.btn-success {
	@apply text-sm text-white font-normal rounded-md py-2 px-3 transition-colors bg-green-500 hover:bg-green-400 active:bg-green-600 focus:outline-none;
}

.input {
	@apply mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}

.modal-btn-red {
	@apply inline-flex mr-3 transition-colors justify-center px-4 py-2 text-sm font-medium bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none text-red-900;
}

.modal-btn-indigo {
	@apply inline-flex transition-colors justify-center px-4 py-2 text-sm font-medium bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none text-indigo-900;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #9092f1 #d1d5db;
}

/* https://www.w3generator.com/scrollbar */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background: #9092f1;
	border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
	background: #4a4cce;
}

::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 7px;
	box-shadow: inset 7px 10px 12px #f0f0f0;
}

.gradient-blue {
	background: linear-gradient(180deg, #89e5fe 0%, #46c6eb 100%);
	box-shadow: 6px 5px 16px -8px rgb(74 74 74 / 75%);
}

.ant-tabs-ink-bar {
	height: 8px;
	background: #3133da !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #3133da;
}

.ant-tabs-tab {
	min-width: 60px;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: #4a4ddd;
}

.ant-menu-item {
	margin: 12px;
	border-radius: 6px;
}

.ant-menu-item a {
	color: black;
}

.ant-menu-item .ant-menu-item-selected {
	background-color: #543dd3;
}

.ant-menu-item .ant-menu-item-selected a {
	color: white;
}

.ant-menu-item.ant-menu-item-selected .anticon.ant-menu-item-icon svg {
	color: white !important;
}

.ant-menu-inline .ant-menu-item::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	content: "";
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #3133da;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
	color: white;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: auto;
}

.site-layout .site-layout-background {
	background: #fff;
}

.ant-notification-notice {
	border-radius: 8px;
}

.access-log table th {
	background-color: #4a4cce;
	color: white;
	text-align: center;
}

table tbody tr td {
	text-align: left;
}

.text-jumbo {
	font-size: 60px;
	font-weight: 700;
}

.text-ginormous {
	font-size: 145px;
}

.site-description-item-profile-wrapper {
	margin-bottom: 7px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
	font-size: 12px;
	line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
	display: block;
	margin-bottom: 16px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
	font-size: 14px;
	line-height: 1.66667;
}

.site-description-item-profile-p-label {
	display: inline-block;
	margin-right: 8px;
	color: rgba(0, 0, 0, 0.85);
}

.gradient-bg {
	background: linear-gradient(285deg, #faf4f9, #f3f8fc 80%);
}

#logs .ant-table-thead .ant-table-filter-trigger-container .active {
	color: white;
}

#logs .ant-table-thead .ant-table-filter-trigger-container:hover,
#logs
	.ant-table-thead
	.ant-table-filter-trigger-container:hover
	.ant-dropdown-open {
	background-color: #3b3cac;
}

progress {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}

progress::-webkit-progress-bar {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}

progress::-webkit-progress-value {
	@apply bg-indigo-500 rounded-md;
}

progress::-moz-progress-bar {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}

button[disabled] {
	cursor: not-allowed;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	padding: 0;
}

@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}

	50% {
		transform: translateY(4%);
	}

	65% {
		transform: translateY(-2%);
	}

	80% {
		transform: translateY(2%);
	}

	95% {
		transform: translateY(-1%);
	}

	100% {
		transform: translateY(0%);
	}
}

.popup {
	animation-name: fadeIn;
	animation-duration: 0.4s;
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	left: 0px;
	list-style-type: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: absolute;
	text-align: left;
	top: 0px;
	overflow: hidden;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
	clear: both;
	color: rgba(0, 0, 0, 0.65);
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	margin: 0;
	padding: 5px 12px;
	transition: all 0.3s;
	white-space: nowrap;
	-webkit-transition: all 0.3s;
}

.popup li:hover {
	background-color: #e6f7ff;
}

.popup li > i {
	margin-right: 8px;
}

.ant-message {
	position: absolute;
	width: fit-content;
	bottom: 20px;
	top: initial;
	right: 20px;
	left: initial;
}

.ant-btn-primary {
	background-color: #1890ff !important;
}

.wmde-markdown ul {
	list-style-type: disc;
}

.wmde-markdown ol {
	list-style-type: decimal;
}
